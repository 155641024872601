import axios from 'axios';
import Vue from 'vue';
import merge from 'lodash/merge';
import union from 'lodash/union';
import get from 'lodash/get';
import moment from 'moment';
import holidays from './holidays';
import accounting from 'accounting';
import PriceCalculatorAPI from '../api/price-calculator';
import ScoreCalculator from '../api/score-calculator';
import InsurerSorting from '../api/insurer-sorting';
import CryptoJS from "crypto-js";
import DataCheck from './helpers/DataCheck'
import Logger from './helpers/Logger'
import ConditionCheck from './ConditionCheck.js'


const API_BASE = process.env.API_BASE || process.env.VUE_APP_API_BASE;
const axiosRef = Vue.http2 ? Vue.http2 : axios;
const priceMap = {
  '1-10': {
    0: 176,
    150: 110,
    250: 99,
    500: 88
  },
  '11-20': {
    0: 354,
    150: 221,
    250: 199,
    500: 177
  },
  '21-30': {
    0: 532,
    150: 332,
    250: 299,
    500: 266
  },
  '31-40': {
    0: 709,
    150: 443,
    250: 399,
    500: 355
  },
  '41-50': {
    0: 887,
    150: 554,
    250: 499,
    500: 444
  }
};
const REVENUE_RANGES = [
  0,
  100000,
  150000,
  250000,
  500000,
  750000,
  1000000,
  1500000,
  2000000,
  2500000,
  3000000,
  4000000,
  5000000,
  7500000,
  10000000,
  10000000,
  12500000,
  15000000,
  17500000,
  20000000,
  22500000,
  25000000,
  27500000,
  30000000,
  32500000,
  35000000,
  37500000,
  40000000,
  42500000,
  45000000,
  47500000,
  50000000,
  50000000,
  55000000,
  60000000,
  65000000,
  70000000,
  75000000,
  80000000,
  85000000,
  90000000,
  95000000,
  100000000
]

const now = moment();
let defaultStartDate;
let store;

while (typeof defaultStartDate === 'undefined') {
  if (!holidays.customPredictor(now.toDate())) {
    defaultStartDate = now.hours(12).toDate();
  }

  now.add(1, 'day');
}

const getDefaultInputsState = () => ({
  netTurnOver: 0,
  onlineRevenue: 0,
  insurer: null,
  selectedModules: {
    // the insurers will be added based on dynamic
  },
  meta: {},
  insuredSum: 0,
  deductible: 1000,
  paymentPeriod: 'yearly',
  answers: {},
  form: {},
  type: null,
  industry: {
    l: '',
    w: '-1'
  },
  email: '',
  company: '',
  salutation: '',
  title: '',
  firstname: '',
  lastname: '',
  street: '',
  postal: '',
  city: '',
  phone: '',
  dueDate: false,
  billingDate: moment()
    .add(1, 'year')
    .startOf('day')
    .toDate(),
  startDate: defaultStartDate,
  paymentMethod: '',
  newsletterCheck: false,
  checkRevocation: false,
  checkContractDocuments: false,
  checkBrokerTerms: false,
  checkVVG: false,
  offeredProducts: [],
  offer: null,
  employees: '1-10',
  sepa: {
    iban: '',
    firstname: '',
    lastname: '',
    company: '',
    website: '',
    street: '',
    postal: '',
    city: ''
  },
  notification: {
    customer: true,
    broker: true
  },
  legal: {},
  additionalNotes: '',
  maxOffers: 4,

});

export {
  store
};
export default function generateStore(mode = 'www', style, isOffer = false) {
 const state = {
    application: {
      modulesChanged: false,
      activeStep: {},
      insuranceProperties: require('../content/insurance-properties.json').elements, // deprecated
      insuranceProperties2_0: {},
      overallBenchmark: 0,
      insurers: [],
      insurersUpdateToken: 1,
      insurersWithScores: [],
      insurersWithScoresUpdateToken: 1,
      printInsurers: [''],
      legalInsurers: [],
      questions: {},
      industries: [],
      steps: require('../content/steps.json').steps,
      legalSteps: [],
      serviceProvider: require('../content/service-provider').serviceProvider,
      prices: {},
      questionCategories: null,
      mode,
      style,
      affiliateBroker: null,
      affiliateToken: null,
      inputs: getDefaultInputsState(),
      selectedModulesType: 'default',
      offer: {
        selectedInsurer: []
      },
      broker: {
        legalCheck1: false
      },
      public: {
        legalCheck: false
      },
      cyberPrices: {},
      isScoreVisible: true,
      isPrintMode: false,
      isCheckoutLoadingOverlay: false,
      isInitialDataLoaded: false,
      isPartialInitLoaded: false,
      isInitialDataLoading: null,
      isRQPopupShown: false,
      insTaxRate: {
        default: '19',
        aon: '11'
      },
      maxOffers: 4,
      showContactBanner: false,
      insurerSortingKey: 'abc',
    }
  };

  store = {
    state,
    actions: {
      // TODO
      // async FLATTEN_INSURANCE_ELEMENTS(context, insuranceProperties) {
      //   const elements = [];
      //   for (const group in insuranceProperties.groups) {
      //     for (const subgroup in group.subgroups) {
      //       elements.push({
      //         key: subgroup.key,
      //         benchmark: subgroup.key,
      //       });
      //     }
         
      //   }

      //   console.log('FLATTEN_INSURANCE_ELEMENTS', elements);
      // },
      async CALCULATE_SCORES(context, updatedInsurerPrices) {
        Logger('CALCULATE_SCORES', updatedInsurerPrices);
        let insurers = context.state.application.insurers

        const insurersWithScores = ScoreCalculator.calculateScores(
          insurers,
          context.state.application.insuranceProperties2_0,
          context.state.application.overallBenchmark,
          isOffer ? context.getters.offer : context.state.application.inputs, // if offer, use different selectedModules
          updatedInsurerPrices,
          context.getters.usedQuestionsByInsurers
        )

        // insurersWithScores.forEach((ie) => {
          
        //   const insIndex = context.state.application.insurers.findIndex(ins => ins.key === ie.insurer.key)
        //   if(insIndex === -1) { 
        //     Logger('insurer not found', ie.insurer.key, 'error')
        //     return
        //   }
        //   // this needs rework!
        //   // is not needed to have a trilion of updates here
        //   context.commit('SET_SCORE_FOR_INSURER', {insKey: ie.insurer.key, score: ie.sumScore})
        // })

        context.commit('SET_SCORES_FOR_INSURER', insurersWithScores)

        Logger('setting insurersWithScores', insurersWithScores)
        context.commit('SET_INSURERS_WITH_SCORES', insurersWithScores)
        return true
      },
      async GET_DYNAMIC_DATA(context, { type, tariff }) {
        const params = {}
        if(type) {
          params[type] = true
        }
        if(tariff) {
          params.tariffKeys = tariff
        }

        const rsp = await axiosRef.get(`${API_BASE}insurers/get-init-data/`, { 
          params: params
        }).catch(err => {
          Logger('GET_DYNAMIC_DATA', err, 'error');
          return false
        });

        if (!rsp) return false
        const decryptedData = await context.dispatch('DECRYPT_INIT_DATA', { rsp, type: 'default' });
        Logger(`${type} - received`)
        return decryptedData
      },
      async GET_PARTIAL_INITIAL_DATA(context) {
        const promises = [
          context.dispatch('GET_DYNAMIC_DATA', { type: 'industries', tariff: null }),
          context.dispatch('GET_DYNAMIC_DATA', { type: 'staticData', tariff: null }),
        ]
        const [industries, staticData] = await Promise.all(promises).catch(err => {
          throw err
        });    
        
        const initData = {
          overallBenchmark: staticData.overallBenchmark,
          industries: industries.industries,
          legalSteps: staticData.legalSteps,
        }

        context.commit('SET_PARTIAL_INIT_DATA', initData);
        context.commit('SET_PARTIAL_INIT_STATUS', true);
        return initData
      },
      async GET_INITIAL_DATA(context, tariffKeys = null) {
        
        Logger('getting initial data for checkout');
        const promises = [
          context.dispatch('GET_DYNAMIC_DATA', { type: 'insurers', tariff: tariffKeys }),
          context.dispatch('GET_DYNAMIC_DATA', { type: 'questions', tariff: tariffKeys }),
          context.dispatch('GET_DYNAMIC_DATA', { type: 'prices', tariff: tariffKeys }),
          context.dispatch('GET_DYNAMIC_DATA', { type: 'industries' }),
          context.dispatch('GET_DYNAMIC_DATA', { type: 'questionCategories' }),
          context.dispatch('GET_DYNAMIC_DATA', { type: 'insuranceProperties' }),
          context.dispatch('GET_DYNAMIC_DATA', { type: 'staticData' }),
        ]

        const [insurers, questions, prices , industries, questionCategories, insuranceProperties, staticData] = await Promise.all(promises);
        // TODO change to tr20
        // context.dispatch('FLATTEN_INSURANCE_ELEMENTS', insuranceProperties.insuranceProperties)

        const initData = {
          overallBenchmark: staticData.overallBenchmark,
          insuranceProperties: insuranceProperties.insuranceProperties, 
          insurers: insurers.insurers,
          questions: questions.questions,
          industries: industries.industries,
          legalSteps: staticData.legalSteps,
          // steps: staticData.steps,
          prices: prices.prices,
          questionCategories: questionCategories.questionCategories,
        }
        // if (initData.length === 0 ) console.error('insurer property elements are empty')
       
        Logger('initData', initData)
        context.dispatch('CHECK_INITIAL_DATA', initData);
        context.commit('SET_INIT_DATA', initData);
        context.commit('SET_INIT_STATUS', null);

        // ... why
        if (!context.state.application.activeStep.id || Number(context.state.application.activeStep.id[0]) < 3) {
          context.commit('SET_DEFAULT_SELECT_MODULES', context.getters.getDefaultInputs);
        }
        return true
      },
      // TODO: connect DECRYPT -> GET_INIT
      DECRYPT_INIT_DATA(context, { rsp, type }) {
        
        const pricesKey = 'NiTr+nyIdZudKB-j5i)MdyKU5IM2aS2x'
        const propertiesKey = 's5v8y/B?E(H+MbQeThVmYq3t6w9z$C&F'     

        const secretKey = type === 'default' ? propertiesKey : pricesKey;
        const data = type === 'default' ? rsp.data.initdata : rsp.data;

        const bytes  = CryptoJS.AES.decrypt(data, secretKey);
        const decryptedData = JSON.parse(bytes.toString(CryptoJS.enc.Utf8));
        // Logger('decryptedData', decryptedData)
        if (decryptedData.length === 0 ) console.error('decrypted data is empty')
        return decryptedData
      },
      CHECK_INITIAL_DATA(context, data) {
        // comparing the dynamic insurer data to the old static jsons and log differences
        // basically to debug
        // const {insuranceProperties, insurers, questions, prices, overallBenchmark } = data;
        const { overallBenchmark } = data;

        DataCheck(overallBenchmark, null, 'overallBenchmark');
        // DataCheck(insuranceProperties, context.state.application.insuranceProperties2_0, 'insuranceProperties');
        // DataCheck(insurers, context.state.application.insurers, 'insurers');
        // DataCheck(prices, context.state.application.prices, 'prices');
        // DataCheck(questions, context.state.application.questions, 'questions');
      },
      async CALCULATE_CYBER_PRICES(context, options = {}) {
        Logger('CALCULATE_CYBER_PRICES');
        let {
          netTurnOver,
          selectedModules,
          deductible,
          paymentPeriod,
          industry,
          answers,
          meta,
          key,
          form
        } = context.state.application.inputs;
        let insuredSum = options.insuredSum || context.state.application.inputs.insuredSum


        if (!options.withAnswers) {
          answers = {};
        }

        if (options.isRewriteInputs) {
          insuredSum = options.rewrite.insuredSum
          netTurnOver = options.rewrite.netTurnOver
          selectedModules = options.rewrite.selectedModules
          deductible = options.rewrite.deductible
          paymentPeriod = options.rewrite.paymentPeriod
          industry = options.rewrite.industry
          answers = !options.withAnswers ? {} : options.rewrite.answers
          meta = options.rewrite.meta
          key = options.rewrite.key
          form = options.rewrite.form
        }

        let agencyKey = '';

        if (context.state.application.affiliateBroker) {
          agencyKey = context.state.application.affiliateBroker.agency;
        } else if (key) {
          agencyKey = key
        } else if (context.getters.agencyKey) {
          agencyKey = context.getters.agencyKey;
        }
        const response = await PriceCalculatorAPI.calculateCyberPremium(
          netTurnOver,
          insuredSum,
          selectedModules,
          deductible,
          paymentPeriod,
          industry,
          answers,
          options.filter,
          meta,
          agencyKey,
          form
        ).catch(err => {
          context.commit('SET_INIT_STATUS', null);
          console.error('Loading error:', err)
        })
        if(typeof response.data === 'string') {
          const decryptedData = await context.dispatch('DECRYPT_INIT_DATA', { rsp: response, type: 'prices' });      
          if (options.isOptionalPrices) {
            return decryptedData
          }
          try {
            await context.dispatch('CALCULATE_SCORES', decryptedData);
            context.commit('UPDATE_PRICES', decryptedData);
            context.commit('SET_INIT_STATUS', null);
          } catch (e) {
            console.error('E', e)
            throw e
          }
          return decryptedData
        } else {
          if (options.isOptionalPrices) {
            return response.data
          }
          await context.dispatch('CALCULATE_SCORES', response.data);
          context.commit('UPDATE_PRICES', response.data);
          context.commit('SET_INIT_STATUS', null);
          return response.data
        }
      },
      CALCULATE_CYBER_PRODUCTS(context) {
        let {
          insurer,
          netTurnOver,
          selectedModules,
          insuredSum,
          deductible,
          paymentPeriod,
          industry,
          answers,
          meta,
          form
        } = context.state.application.inputs;
        const possibleInsuredSums = context.getters.getInsuredSums(insurer);
        const selectedInsuredSumIndex = possibleInsuredSums.indexOf(insuredSum);
        const productsLastIndex = possibleInsuredSums.length - 1;
        const insuredSums = [];
        const productNames = ['M', 'L', 'XL'];
        let insuredSumsIndexes = [];

        if (selectedInsuredSumIndex === 0) {
          insuredSumsIndexes = [0, 1, 2];
        } else if (selectedInsuredSumIndex === productsLastIndex) {
          insuredSumsIndexes = [productsLastIndex - 2, productsLastIndex - 1, productsLastIndex];
        } else {
          insuredSumsIndexes = [selectedInsuredSumIndex - 1, selectedInsuredSumIndex, selectedInsuredSumIndex + 1];
        }

        insuredSumsIndexes.forEach((insuredSumIndex) => {
          insuredSums.push(possibleInsuredSums[insuredSumIndex]);
        });

        answers = {};

        let agencyKey = '';

        if (context.state.application.affiliateBroker) {
          agencyKey = context.state.application.affiliateBroker.agency;
        } else if (context.getters.agencyKey) {
          agencyKey = context.getters.agencyKey;
        }

        return PriceCalculatorAPI
          .calculateCyberPremiumProducts(insurer, netTurnOver, productNames, insuredSums, selectedModules, deductible, paymentPeriod, industry, answers, meta, form, agencyKey)
          .then((response) => {
            if(typeof response.data === 'string') {
              const secretKey = 'NiTr+nyIdZudKB-j5i)MdyKU5IM2aS2x';
              const bytes  = CryptoJS.AES.decrypt(response.data, secretKey);
              const decryptedData = JSON.parse(bytes.toString(CryptoJS.enc.Utf8));

              context.commit('UPDATE_PRODUCTS', decryptedData.products);
              return decryptedData.products
            } else {
              context.commit('UPDATE_PRODUCTS', response.data.products);
              return response.data.products
            }
          });
      },
      CALCULATE_CYBER_PRODUCT_OPTIONS(context, {
        insuredSums, productNames = ['Alternative 1', 'Alternative 2', 'Alternative 3'], insurer, netTurnOver, selectedModules, deductible, industry, paymentPeriod, answers, meta, form
      }) {
        if (!insuredSums || !insuredSums.length) {
          return console.error('insuredSums are required for options price calculation')
        }
        if (!insurer) {
          return console.error('insurer is required for options price calculation')
        }

        let agencyKey = '';

        if (context.state.application.affiliateBroker) {
          agencyKey = context.state.application.affiliateBroker.agency;
        } else if (context.getters.agencyKey) {
          agencyKey = context.getters.agencyKey;
        }

        return PriceCalculatorAPI
          .calculateCyberPremiumProducts(insurer, netTurnOver, productNames, insuredSums, selectedModules, deductible, paymentPeriod, industry, answers, meta, form, agencyKey)
          .then((response) => {
            if(typeof response.data === 'string') {
              const secretKey = 'NiTr+nyIdZudKB-j5i)MdyKU5IM2aS2x';
              const bytes  = CryptoJS.AES.decrypt(response.data, secretKey);
              const decryptedData = JSON.parse(bytes.toString(CryptoJS.enc.Utf8));
              context.commit('UPDATE_PRODUCTS', decryptedData.products);
              return decryptedData.products
            } else {
              context.commit('UPDATE_PRODUCTS', response.data.products);
              return response.data.products
            }
          });
      },
      UPDATE_INPUT_VALUES(context, inputValues) {
        context.commit('UPDATE_INPUT_VALUES', inputValues);
      },
      UPDATE_LEGAL_INPUTS(context, newValues) {
        context.commit('UPDATE_LEGAL_INPUTS', newValues);
      },
      UPDATE_STEPS_VALUES(context, stepsValues) {
        context.commit('UPDATE_STEPS_VALUES', stepsValues);
      },
      UPDATE_ACTIVE_STEP(context, activeStepValue) {
        context.commit('UPDATE_ACTIVE_STEP', activeStepValue);
      },
      DECODE_AFFILIATE_TOKEN(context, token) {
        return axiosRef
          .post(`${API_BASE}brokers/decodeAffiliateToken`, { token })
          .then((response) => {
            context.commit('SET_AFFILIATE_BROKER', response.data);
            context.commit('SET_AFFILIATE_TOKEN', token);
          });
      },
      INSERT_PREREGISTRATION(context, data) {
        return axiosRef
          .post(`${API_BASE}preRegistrations`, data);
      },
      INSERT_REGISTRATION(context, data) {
        return axiosRef
          .post(`${API_BASE}registrations`, data, { withCredentials: true });
      },
      INSERT_AFFILIATE_OFFER(context, data) {
        const {
          netTurnOver,
          insurer,
          selectedModules,
          insuredSum,
          deductible,
          paymentPeriod,
          industry,
          meta,
          form
        } = context.state.application.inputs;

        const offerSelectedModules = {}

        Object.keys(selectedModules).forEach(insurerKey => {
          if (data.selection && data.selection.indexOf(insurerKey) > -1) {
            offerSelectedModules[insurerKey] = selectedModules[insurerKey]
          }
        })

        const reqData = {
          netTurnOver,
          insurer,
          selectedModules: offerSelectedModules,
          insuredSum,
          deductible,
          paymentPeriod,
          industry,
          meta,
          form
        };

        merge(reqData, data);
        delete reqData.salutation;

        reqData.salutationOverwrite = `Guten Tag ${data.firstname} ${data.lastname},`;

        return axiosRef
          .post(`${API_BASE}offers/createFromAffiliateLink`, {
            offer: reqData,
            token: context.state.application.affiliateToken
          });
      },
      INSERT_OFFER(context, data) {
        const {
          netTurnOver,
          insurer,
          selectedModules,
          insuredSum,
          deductible,
          paymentPeriod,
          industry,
          meta,
          form
        } = context.state.application.inputs;

        const offerSelectedModules = {}

        Object.keys(selectedModules).forEach(insurerKey => {
          if (data.selection.indexOf(insurerKey) > -1) {
            offerSelectedModules[insurerKey] = selectedModules[insurerKey]
          }
        })

        const reqData = {
          netTurnOver,
          insurer,
          selectedModules: offerSelectedModules,
          insuredSum,
          deductible,
          paymentPeriod,
          industry,
          meta,
          form
        };
        const { userId } = context.rootState.auth;

        merge(reqData, data);

        return axiosRef
          .post(`${API_BASE}brokers/${userId}/offer`, reqData);
      },
      INSERT_SUBMISSION(context, data) {
        return axiosRef
          .post(`${API_BASE}submissions`, data);
      },
      LEGAL_SET_PRICE(context) {
        context.commit('LEGAL_SET_PRICE');
      },
      PREPARE_RESET_INPUTS(context, defaults = {}) {
        // here we can link in the precached defaults inputs from getters with dynamic data
        // and inject it into the RESET_INPUTS
        context.commit('RESET_INPUTS', context.getters.getDefaultInputs, defaults);
      },
      async STEP4_DATA_UPDATE(context) {
        // check if theres an offer
        // if there is, send a request to BE to update the submodules data
        if(context.state.application.inputs.offer && context.state.application.inputs !== '') {
          // const inputs = context.state.application.inputs

          const offerId = context.state.application.inputs.offer
          const { data: offer } = await axiosRef.get(`${API_BASE}offers/${offerId}/`)
            .catch(err => {
              console.error(' offer impossible to get')
            });

          // uupdate the state if the state mutation is accessible
          if(!!this._mutations['SET_OFFER']) {
            context.commit('SET_OFFER', offer)
          }

          // check if we have insurers name and then filter the selected modules
          let insurerSelectedModules = null
          if (context.state.application.inputs.insurer) {
            const insurerKey = context.state.application.inputs.insurer
            insurerSelectedModules = {}
            insurerSelectedModules[insurerKey]  = offer.selectedModules[insurerKey]
          }
          context.commit('SET_SELECTED_MODULES', insurerSelectedModules ? insurerSelectedModules : offer.selectedModules)

          const inputKeysToUpdate = ['dueDate', 'billingDate', 'street', 'postal', 'city', 'title', 'paymentMethod', 'sepa', 'additionalNotes']

          const inputsToUpdate = {}

          inputKeysToUpdate.forEach(input => {
            if (offer[input] !== undefined && offer[input] !== null && !(typeof offer[input] === 'object' && Object.keys(offer[input])?.length === 0)) {
              inputsToUpdate[input] = offer[input]
            }
          })

          context.commit('SET_INPUTS', inputsToUpdate)

          // look for price Overwrite
          if(offer.priceOverwrite) {
            return offer.priceOverwrite
          } else {
            return null
          }
        }

        // if not update the prices
        await context.dispatch('CALCULATE_CYBER_PRICES', {
          filter: context.state.application.inputs.insurer,
          withAnswers: true }
        )

        return true
      }
    },
    mutations: {
      SET_RQ_POPUP_STATE(state) {
        state.application.isRQPopupShown = true
      },
      SET_SORTING_LOGIC(state, sortingKey) {
        state.application.insurerSortingKey = sortingKey
      },
      SET_DEFAULT_SELECT_MODULES(state, defaultState) {
        state.application.inputs.selectedModules = defaultState.selectedModules
      },
      SET_SELECTED_MODULES(state, selectedModules) {
        state.application.inputs.selectedModules = selectedModules
      },
      SET_PARTIAL_INIT_STATUS(state, boo) {
        state.application.isPartialInitLoaded = boo;
      },
      RESET_INIT_STATUS(state, boo) {
        state.application.isInitialDataLoaded = boo;
        console.log('RESET_INIT_STATUS', state.application.isInitialDataLoaded);
      },
      SET_INIT_STATUS(state, boo) {
        state.application.isInitialDataLoading = boo;
      },
      SET_PARTIAL_INIT_DATA(state, payload = {}) {
        state.application.overallBenchmark = payload.overallBenchmark || 196.5 // hardcoded;
        state.application.legalSteps = payload.legalSteps;
        state.application.industries = payload.industries;
        state.application.insurers = [];
      },
      SET_INIT_DATA(state, payload = {}) {
        Logger('SET_INIT_DATA', payload);

        // state.application.insuranceProperties = [];
        state.application.overallBenchmark = payload.overallBenchmark || 196.5 // hardcoded;
        state.application.insuranceProperties2_0 = payload.insuranceProperties;
        // TODO, this is nonsense
        // state.application.insuranceProperties = require('../content/insurance-properties.json').elements,
        state.application.insurers = payload.insurers;
        state.application.questions = payload.questions;
        state.application.industries = payload.industries;
        state.application.legalSteps = payload.legalSteps;
        state.application.prices = payload.prices;
        state.application.questionCategories = payload.questionCategories
        state.application.isInitialDataLoaded = true
      },
      RESET_INPUTS(state, defaultInputs, defaults = {}) {
        Object.assign(state.application.inputs, defaultInputs, defaults);
        state.application.selectedModulesType = 'default';
        state.application.modulesChanged = false;
      },
      RESET_INPUTS_WITH_MAP(state, obj) {
        Object.keys(obj).forEach(key => {
          state.application.inputs[key] = obj[key];
        })
      },
      RESET_OFFER(state) {
        state.application.inputs.offer = null;
        state.application.inputs.additionalNotes = '';
      },
      RESET_ANSWERS(state) {
        state.application.inputs.answers = {};
      },
      SET_ANSWERS(state, answers) {
        state.application.inputs.answers = answers
      },
      UPDATE_PRODUCTS: (state, products) => {
        // filter empty or 0 cost products
        const filteredProducts = products.filter((product) => product.gross && product.gross > 0)
        Vue.set(state.application, 'products', [...filteredProducts]);
      },
      UPDATE_PRICES: (state, prices) => {
        state.application.cyberPrices = prices;
      },
      SELECT_ALL_MODULES: (state) => {
        // state.application.insurers.forEach((insurer) => {
        //   const newSelectedModules = [];
        //   for (const propertyKey in insurer.properties) {
        //     if (
        //       ["optional-preselected", "optional"].indexOf(
        //         insurer.properties[propertyKey].state
        //       ) > -1
        //     ) {
        //       if (!state.application.inputs.selectedModules[insurer.key]) {
        //         console.error("missing selectedModule", insurer.key);
        //       }
        //       if (
        //         state.application.inputs.selectedModules[insurer.key] &&
        //         state.application.inputs.selectedModules[insurer.key].indexOf(
        //           propertyKey
        //         ) === -1
        //       ) {
        //         newSelectedModules.push(propertyKey);
        //       }
        //     }
        //   }
        //   state.application.inputs.selectedModules[insurer.key] = union(
        //     newSelectedModules,
        //     state.application.inputs.selectedModules[insurer.key]
        //   );
        // }, this);

        // state.application.selectedModulesType = "all";
        // state.application.modulesChanged = true;
        const mutateStore = { ...state.application.inputs.selectedModules };

        state.application.insurers.forEach((insurer) => {
          const newSelectedModules = [];
          for (const propertyKey in insurer.properties) {
            if (
                ["optional-preselected", "optional"].indexOf(
                    insurer.properties[propertyKey].state
                ) > -1
            ) {
              if (!mutateStore[insurer.key]) {
                console.error("missing selectedModule", insurer.key);
              }
              if (
                  mutateStore[insurer.key] &&
                  mutateStore[insurer.key].indexOf(
                      propertyKey
                  ) === -1
              ) {
                newSelectedModules.push(propertyKey);
              }
            }
          }

          mutateStore[insurer.key] = union(
              newSelectedModules,
              mutateStore[insurer.key]
          );
        }, this);

        state.application.inputs.selectedModules = mutateStore;
        state.application.selectedModulesType = "all";
        state.application.modulesChanged = true;
      },
      DEFAULT_SELECT_MODULES: (state) => {
        //   // deselect all
        //   Object.keys(state.application.inputs.selectedModules).forEach(
        //     (insurer) => {
        //       state.application.inputs.selectedModules[insurer] = [];
        //     }
        //   );

        //   // select default
        //   state.application.insurers.forEach((insurer) => {
        //     for (const propertyKey in insurer.properties) {
        //       if (
        //         insurer.properties[propertyKey].state === "optional-preselected"
        //       ) {
        //         if (!state.application.inputs.selectedModules[insurer.key]) {
        //           console.error("missing selectedModule", insurer.key);
        //         }
        //         if (
        //           state.application.inputs.selectedModules[insurer.key] &&
        //           state.application.inputs.selectedModules[insurer.key].indexOf(
        //             propertyKey
        //           ) === -1
        //         ) {
        //           state.application.inputs.selectedModules[insurer.key].push(
        //             propertyKey
        //           );
        //         }
        //       }
        //     }
        //   }, this);

        //   state.application.selectedModulesType = "default";
        //   state.application.modulesChanged = true;

        const mutateStore = { ...state.application.inputs.selectedModules };
        // deselect all

        Object.keys(mutateStore).forEach((insurer) => {
          mutateStore[insurer] = [];
        });

        // select default
        state.application.insurers.forEach((insurer) => {
          for (const propertyKey in insurer.properties) {
            if (
                insurer.properties[propertyKey].state === "optional-preselected"
            ) {
              if (!mutateStore[insurer.key]) {
                console.error("missing selectedModule", insurer.key);
              }
              if (
                  mutateStore[insurer.key] &&
                  mutateStore[insurer.key].indexOf(
                      propertyKey
                  ) === -1
              ) {
                mutateStore[insurer.key].push(propertyKey);
              }
            }
          }
        }, this);

        state.application.inputs.selectedModules = mutateStore;

        state.application.selectedModulesType = "default";
        state.application.modulesChanged = true;
      },
      DESELECT_ALL_MODULE: (state) => {
        // Object.keys(state.application.inputs.selectedModules).forEach(
        //   (insurer) => {
        //     state.application.inputs.selectedModules[insurer] = [];
        //   }
        // );

        // state.application.selectedModulesType = "none";
        // state.application.modulesChanged = true;

        const mutateStore = { ...state.application.inputs.selectedModules };

        Object.keys(mutateStore).forEach((insurer) => {
          mutateStore[insurer] = [];
        });

        state.application.inputs.selectedModules = mutateStore;

        state.application.selectedModulesType = "none";
        state.application.modulesChanged = true;
      },
      SET_MODULES_CHANGED: (state) => {
        state.application.modulesChanged = true;
      },
      SET_OFFER_SELECTION: (state, selection) => {
        // TODO: this needs a better solution
        // if there's a case where there is no cached offer object
        // it might end in an error
        if(!state.application.offer) {
          state.application.offer = {}
          }
        state.application.offer.selection = selection;
      },
      SET_AFFILIATE_BROKER: (state, broker) => {
        state.application.affiliateBroker = broker;
        state.application.testPersisted = 'test...';
      },
      SET_AFFILIATE_TOKEN: (state, token) => {
        state.application.affiliateToken = token;
      },
      UPDATE_INPUT_VALUES: (state, values) => {
        // [FIX ME] lodash.merge() produces a bug in some cases.
        // When we need to overwrite property, instead it merges with previous value.
        merge(state.application.inputs, values);
      },
      UPDATE_OFFER_SELECTION: (state, selection) => {
        // TODO: this needs a better solution
        // if there's a case where there is no cached offer object
        // it might end in an error
        if(!state.application.offer) {
          state.application.offer = {}
        }
        state.application.offer.selectedInsurer = selection;
      },
      UPDATE_LEGAL_INPUTS(state, newValues) {
        merge(state.application.legal.inputs, newValues);
      },
      SELECT_MODULE: (state, data) => {
        if (state.application.inputs.selectedModules[data.insurer].indexOf(data.module) === -1) {
          state.application.inputs.selectedModules[data.insurer].push(data.module);
        }
      },
      DESELECT_MODULE: (state, data) => {
        const moduleIndex = state.application.inputs.selectedModules[data.insurer].indexOf(data.module);

        if (moduleIndex > -1) {
          state.application.inputs.selectedModules[data.insurer].splice(moduleIndex, 1);
        }
      },
      UPDATE_STEPS_VALUES: (state, values) => {
        state.application.steps = values;
      },
      SET_ACTIVE_STEP: (state, activeStep) => {
        state.application.activeStep = activeStep;
        state.application.steps.forEach((step) => {
          step.active = step.id === activeStep.id;
        });
      },
      UPDATE_ACTIVE_STEP: (state, activeStepValue) => {
        state.application.activeStep = activeStepValue;
      },
      LEGAL_SET_PRICE(state) {
        const { employees, deductible } = state.application.inputs;
        if (!employees || deductible === null) {
          return;
        }
        state.application.inputs.gross = priceMap[employees][deductible];
        state.application.inputs.net = accounting.toFixed(state.application.inputs.gross / 1.19, 2);
      },
      SET_PARTNER(state, partnerId) {
        state.application.inputs.affiliate = true;
        state.application.inputs.partnerId = partnerId;
      },
      SET_AFFILIATE(state, affiliateId) {
        state.application.inputs.affiliate = true;
        state.application.inputs.affiliateId = affiliateId;
      },
      SET_SCORE_VISIBILITY(state, isVisible) {
        state.application.isScoreVisible = isVisible
      },
      SET_PRINT_MODE(state, isSet) {
        state.application.isPrintMode = isSet
      },
      SET_INSURERS_WITH_SCORES(state, insurersWithScores) {
        state.application.insurersWithScores = insurersWithScores
        state.application.insurersWithScoresUpdateToken += 1 // to force updating of the computed property
      },
      SET_SCORES_FOR_INSURER(state, insurers) {
        insurers.forEach(ins => {
          const insIndex = state.application.insurers.findIndex(insurer => ins.insurer.key === insurer.key)
          if(insIndex === -1) { console.error('insurer not found', ins.insurer.key) }
          state.application.insurers[insIndex].score = ins.sumScore
        })
        // state.application.insurers = insurers
      },
      // deprecated
      // SET_SCORE_FOR_INSURER(state, {insKey, score}) {
      //   const insIndex = state.application.insurers.findIndex(ins => ins.key === insKey)
      //   if(insIndex === -1) { console.error('insurer not found', insKey) }
      //   state.application.insurers[insIndex].score = score
      // },
      SET_CHECKOUT_LOADING_OVERLAY(state, isLoading) {
        state.application.isCheckoutLoadingOverlay = isLoading
      },
      SET_COUNTRY_INTERFACE(state, {agencyKey, setFrom}) {
        if (state.application) {
          const aonKeys = ['aon', 'aonaustriatest1', 'aonaustriatest2']
          const isAon = aonKeys.some(k => k === agencyKey);
          if (isAon) {
            state.application.countryInterface = {
              value: 'AT', // Austria
              setFrom
            }
          } else {
            state.application.countryInterface = {
              value: 'DE', // Germany
              setFrom
            }
          }
        }
      },
      SHOW_CONTACT_BANNER(state, value) {
        state.application.showContactBanner = value
      },
      SET_APPLICATION_DATA(state, data) {
        Object.entries(data).forEach(entry => {
          const [key, value] = entry;
          state.application[key] = value
        })
      },
      SET_INDUSTRY(state, industry) {
        state.application.inputs.industry = industry
      },
      SET_PAYMENT_PERIOD(state, paymentPeriod) {
        state.application.inputs.paymentPeriod = paymentPeriod
      },
      SET_SEPA_INPUTS(state, sepaObj) {
        state.application.inputs.sepa = sepaObj
      },
      SET_INPUTS(state, inputs) {
        Object.keys(inputs).forEach((input) => {
          state.application.inputs[input] = inputs[input]
        })
      }

    },
    getters: {
      usedQuestionsByInsurers(state, getters) {
        const usedQuestionsByInsurers = {}
        const insurers = getters.filteredInsurers;
        const insurerKeys = insurers.map((ins) => ins.insurerKey) || []
        const qs = state.application.questions || {}
  
        // filter out not used questions
        insurerKeys.forEach((insurerKey) => {
          
          const inputs = { ...state.application.inputs}
          if(!inputs) {
            logger('no inputs found', null, 'error')
            return
          }
          const filteredQuestions = qs[insurerKey].filter((q) => {
            return ConditionCheck.check(inputs, q.conditions, null, null, { passFollowUps: true })
          })
          // debug
          // console.log(insurerKey)
          // console.log('A', qs[insurerKey].length)
          // console.log('B', filteredQuestions.length)
          usedQuestionsByInsurers[insurerKey] = filteredQuestions
        })
        return usedQuestionsByInsurers
      },
      insurerSortingKey(state) {
        return state.application.insurerSortingKey
      },
      // this updates automatically
      getInsurersWithScoresSorted(state, getters) {

        state.application.insurersWithScoresUpdateToken; // to force updating of the computed property
        const criteria = state.application.insurerSortingKey
        let insurersWithScores = state.application.insurersWithScores

        if(!insurersWithScores) {
          Logger('no insurers with scores found', null, 'error')
          return []
        }

        if(!criteria) {
          Logger('no sorting criteria set', null, 'error')
        }

        // if in offer view in customer-portal, only show the selected insurers
        if(isOffer) {
          const filteredIns = insurersWithScores.filter(ins => {
            return getters.offer.selection.some(oi => oi === ins.insurer.key)
          })

          return InsurerSorting.getSortedInsurers(filteredIns, criteria, true )
        }

        // filter insurers so we sort out non-fitting results as it is in filteredInsurers
        // basically copying the logic from there to this results too.
        const filteredIns = insurersWithScores.filter(ins => {
          return getters.filteredInsurers.some(fins => fins.key === ins.insurer.key)
        })
        return InsurerSorting.getSortedInsurers(filteredIns, criteria, true )
      },
      isOfferLoading(state) {
        return state.isOfferLoading
      },
      insurersWithMultiModules(state) {
        // calculates which insurer prices elements have multi module maps
        const multiModuleMap = {}
        const prices = state.application.prices;

        if(!prices) {
          Logger('can not find prices', state.application.prices, 'error')
          return null
        }

        for (const [kr, pr] of Object.entries(prices)) {
          if (pr.modules) {
            for (const [k, v] of Object.entries(pr.modules)) {
              // are there modules within modules?
              if (v && v.modules && Array.isArray(v.modules)) {
                if(Array.isArray(multiModuleMap[kr])) {
                  multiModuleMap[kr].push(k)
                } else {
                  multiModuleMap[kr] = [k]
                }
              }
            }
          }
        };
        return multiModuleMap
      },
      getDefaultInputs(state) {
        const defaults = getDefaultInputsState()
        // prefill it with dynamic insurer data
        state.application.insurers.forEach((insurer) => {
          defaults.selectedModules[insurer.key] = []
        })
        return defaults
      },
      filteredInsuranceProperties(state, getters) {
        try {
          let agency;

          if (style === 'affiliate' && state.application.affiliateBroker) {
            // affiliate checkout
            agency = state.application.affiliateBroker.agency;
          } else if (isOffer) {
            // offer in customer portal
            agency = state.application.inputs.key;
          } else if (mode === 'broker') {
            // checkout in broker portal
            agency = getters.user ? getters.user.agency : undefined;
          } else if (mode === 'www') {
            // checkout in www
            agency = state.application.inputs.key;
          }

          if (!state.application.insuranceProperties) {
            Logger('no insuranceProperties found', null, 'error')
            return []
          }

          return state.application.insuranceProperties.filter((property) => {
            let isPropertyOK = true;

            if (agency) {
              if (property.whitelist) {
                isPropertyOK = property.whitelist.includes(agency);
              } else if (property.blacklist) {
                isPropertyOK = !property.blacklist.includes(agency);
              }
            }

            if (property.industryWhitelist) {
              isPropertyOK = property.industryWhitelist.includes(state.application.inputs.industry.w);
            }

            return isPropertyOK;
          });
        } catch(err) {
          Logger('Store / filteredInsuranceProperties error:', null, 'error')
        }
      },
      legalPrices: state => () => ({
        gross: state.application.inputs.gross,
        net: state.application.inputs.net
      }),
      prices(state) {
        return state.application.cyberPrices;
      },
      products(state) {
        return state.application.products;
      },
      sharablePayload(state) {
        const {
          industry, netTurnOver, deductible, insuredSum, selectedModules, paymentPeriod, meta, form
        } = state.application.inputs;
        const payload = {
          industry,
          netTurnOver,
          deductible,
          insuredSum,
          selectedModules,
          paymentPeriod,
          meta,
          form
        };

        if (style === 'affiliate') {
          payload.key = state.application.affiliateBroker.agency;
        }
        return payload;
      },
      isWWW(state) {
        return state.application.mode === 'www';
      },
      isBroker(state) {
        return state.application.mode === 'broker';
      },
      style(state) {
        return state.application.style;
      },
      affiliateBroker(state) {
        return state.application.affiliateBroker;
      },
      isScoreVisible(state) {
        return state.application.isScoreVisible;
      },
      filteredSteps: state => (isLegal) => {
        if (isLegal) {
          return state.application.legalSteps;
        }
        if (state.application.style === 'affiliate') {
          return state.application.steps.filter(step => !step.hiddenWhere);
        }
        return state.application.steps;
      },
      filteredDeductibles(state) {
        let { insurer } = state.application.inputs;

        return insurer && state.application.prices && state.application.prices[insurer] ? state.application.prices[insurer].deductiblesAvailable : [1000, 2500, 5000];
      },
      filteredPaymentPeriods(state) {
        let { insurer } = state.application.inputs;
        let insurerPaymentPeriods;
        const paymentPeriods = {
          yearly: {
            label: 'Jährlich'
          },
          halfyearly: {
            label: 'Halbjährlich'
          },
          quarterly: {
            label: 'Vierteljährlich'
          },
          monthly: {
            label: 'Monatlich'
          }
        };

        if (insurer && insurer != 'no-recommendation') {
          insurerPaymentPeriods = state.application.prices[insurer].paymentPeriods;

          if (insurerPaymentPeriods) {
            for (const periodKey in paymentPeriods) {
              if (insurerPaymentPeriods[periodKey] === undefined) {
                delete paymentPeriods[periodKey];
              }
            }
          }
        }

        return paymentPeriods;
      },
      getBasePrice: (state) => (insurer) => {
        const { answers } = state.application.inputs;
        const prices = state.application.prices?.[insurer];

        if (!prices) {
          return null;
        }

        if (Array.isArray(prices.base)) {
          return prices.base;
        }

        if (prices.base?.hasOwnProperty(`sb_${state.application.inputs.deductible}`)) {
          return prices.base[`sb_${state.application.inputs.deductible}`];
        }

        if (
          state.application.inputs.industry?.w === '66220a' &&
          prices.hasOwnProperty("base_66220")
        ) {
          return prices.base_66220;
        }

        if (
          Object
            .keys(prices)
            .filter((item) => String(item).startsWith("base_risk_class"))
            .length
        ) {
          return state.application.inputs.industry?.rurv === 2 ?
            prices.base_risk_class2 :
            prices.base_risk_class1;
        }

        if (
          answers &&
          answers[`q-${insurer}_1`] === 'yes' &&
          prices.base?.hasOwnProperty("scada")
        ) {
          return prices.base.scada;
        }

        if (prices.base?.hasOwnProperty("base")) {
          return prices.base.base;
        }

        return void 0;
      },
      // this is used only for rebuilding possible insurer sums,
      // so it doesnt need to be sorted as it is not displayed anywhere
      filteredInsurersSimple(state, getters) {
        const insurers = state.application.insurers.filter((insurer) => {
          const insurerKey = insurer.key;

          if (insurer.hidden) {
            return false;
          }

          const prices = state.application.prices[insurer.key];
          const basePrice = getters.getBasePrice(insurerKey);

          if (!basePrice) {
            return;
          }

          // filter by mode
          if (insurer.mode.indexOf(state.application.mode) === -1) {
            return false;
          }

          // paymentMethod
          if (prices.paymentPeriods[state.application.inputs.paymentPeriod] === undefined) {
            return;
          }

          if (Array.isArray(state.application.prices[insurerKey].base)) {
            const revenueFound = state.application.prices[insurerKey].base.some(priceRow => state.application.inputs.netTurnOver <= priceRow[0]);

            if (!revenueFound) {
              return false;
            }
          }

          // filter by industry
          if (state.application.inputs.industry && state.application.inputs.industry.w) {
            if (insurer.excludedOnlineIndustries && insurer.excludedOnlineIndustries.indexOf(state.application.inputs.industry.w) > -1) {
              return state.application.inputs.onlineRevenue <= 50;
            }

            if (insurer.excludedIndustries) {
              return insurer.excludedIndustries.indexOf(state.application.inputs.industry.w) === -1;
            }

            return insurer.includedIndustries.indexOf(state.application.inputs.industry.w) > -1;
          }

          return true;
        });

        return insurers;
      },
      filteredInsurers(state, getters) {
        // possible fix for VUEX errs
        let copiedInsurers = state.application.insurers.slice()
        const insurers = copiedInsurers.filter((insurer) => {
          const insurerKey = insurer.key;

          if (insurer.hidden) {
            return false;
          }

          insurer.prices = state.application.cyberPrices[insurer.key];
          const prices = state.application.prices?.[insurer.key];
          const basePrice = getters.getBasePrice(insurerKey);
          let indexRevenue = -1;
          let indexInsuredSum = -1;

          // calc price and filter by price 0
          if (!insurer.prices || insurer.prices.net === 0 || insurer.prices.net === null || isNaN(insurer.prices.net)) {
            return false;
          }

          // filter by  mode
          if (insurer.mode.indexOf(state.application.mode) === -1) {
            return false;
          }

          // ... available insurance sum and revenue ...
          if (!basePrice ||
            state.application.inputs.netTurnOver > basePrice[basePrice.length - 1][0] ||
            basePrice[0].indexOf(state.application.inputs.insuredSum) === -1) {
            return;
          }

          // ... and price is not null
          // find insuredSum band
          basePrice[0].some((price, index) => {
            if (price >= state.application.inputs.insuredSum) {
              indexInsuredSum = index;
              return true;
            }
            return false;
          });

          // find revenue band
          basePrice.some((price, index) => {
            if (price[0] >= state.application.inputs.netTurnOver) {
              indexRevenue = index;
              return true;
            }
            return false;
          });

          // paymentMethod
          if (prices.paymentPeriods[state.application.inputs.paymentPeriod] === undefined) {
            return;
          }

          if (basePrice[indexRevenue][indexInsuredSum] === 0) {
            return;
          }

          // filter BYOD in hiscox
          if (state.application.inputs.industry) {
            Object
              .keys(insurer.properties)
              .forEach((propertyKey) => {
                if (insurer.properties[propertyKey].excludedIndustries) {
                  insurer.properties[propertyKey].disabled = insurer.properties[propertyKey].excludedIndustries.indexOf(state.application.inputs.industry.w) > -1;
                } else {
                  insurer.properties[propertyKey].disabled = false;
                }
              });
          }

          if (getters.getDeductibles(insurerKey).indexOf(state.application.inputs.deductible) === -1) {
            return false;
          }

          // filter by revenue
          if (Array.isArray(state.application.prices[insurerKey].base)) {
            const revenueFound = state.application.prices[insurerKey].base.some(priceRow => state.application.inputs.netTurnOver <= priceRow[0]);

            if (!revenueFound) {
              return false;
            }
          }

          // filter by industry
          if (state.application.inputs.industry && state.application.inputs.industry.w) {
            if (insurer.excludedOnlineIndustries && insurer.excludedOnlineIndustries.indexOf(state.application.inputs.industry.w) > -1) {
              return state.application.inputs.onlineRevenue <= 50;
            }

            if (insurer.excludedIndustries) {
              return insurer.excludedIndustries.indexOf(state.application.inputs.industry.w) === -1;
            }

            return insurer.includedIndustries.indexOf(state.application.inputs.industry.w) > -1;
          }


          return true;
        });

        // sorting function by score/price/abc
        const criteria = state.application.insurerSortingKey
        const qs = state.application.questions || {}

        insurers.forEach((i) => {
          
          const inputs = { ...state.application.inputs}
          if(!inputs) {
            logger('no inputs found', null, 'error')
            return
          }
          const filteredQuestions = qs[i.key].filter((q) => {
            return ConditionCheck.check(inputs, q.conditions, null, null, { passFollowUps: true })
          })

          // debug
          // console.log(i.key)
          // console.log('A', qs[i.key].length)
          // console.log('B', filteredQuestions.length)

          i.questionsCount = filteredQuestions.length
        })

        const sortedInsurers = InsurerSorting.getSortedInsurers(insurers, criteria)

        return sortedInsurers;
      },
      getDeductibles: state => (insurer) => {
        let deductibles = [];

        // see insurers for the given industry
        // at the moment we show all deductibles for all insurers. Perhaps later we change/fine tune this.

        // const {  netTurnOver, deductible, insuredSum, answers }
        // const { deductible, insuredSum, industry} = state.application.inputs;
        deductibles = state.application.prices[insurer].deductiblesAvailable;
        return deductibles;
      },
      // filter available insurers by current industry
      insurersByIndustry: (state) => {
        const insurersByIndustry = []
        const insurersByIndustryKeys = []
        const { industry } = state.application.inputs;

        if(!industry) {
          Logger('no industry selected', state.application.inputs, 'error')
          return []
        }
         // see insurers for the given industry
        // check if they re not in the white/blacklist
        state.application.insurers.forEach((insurer) => {

          // is insurer excluded from industry?
          // if the industry is in the list, dont show it
          if (insurer.excludedIndustries) {
            const match = insurer.excludedIndustries.find(i => i === industry.w)
            if (match) {
              return
            }
          }
          // is insurer excluded from industry?
          // if the industry is in the list, dont show it otherwise hide it
          if (insurer.includedIndustries) {
            const match = insurer.includedIndustries.find(i => i === industry.w)
            if (match) {
              insurersByIndustry.push(insurer);
              insurersByIndustryKeys.push(insurer.key);
              return
            } else {
              return
            }
          }
          // default
          insurersByIndustry.push(insurer);
          insurersByIndustryKeys.push(insurer.key);
        })
        return insurersByIndustry
      },
      possibleDeductibles: (state, getters) => (insurers = getters.filteredInsurersSimple) => {
        const deductibles = new Set()

        const { industry } = state.application.inputs;
        // get the industry
        // industry.w is the UID
        // see insurers for the given industry
        // check if they re not in the white/blacklist
        // const { deductible, insuredSum, industry} = state.application.inputs;
        const insurersByIndustry = getters.insurersByIndustry

        const recommendedInsurer = state.application.inputs.insurer
        const isOfferCockpit = state.application.mode === 'broker' && getters.offer && window && window.location.pathname.includes('/offers')

        if (isOfferCockpit && recommendedInsurer && recommendedInsurer !== 'no-recommendation') {

          let insurerKey = typeof recommendedInsurer === 'string' ? recommendedInsurer : recommendedInsurer.key;

          getters.getDeductibles(insurerKey)
          .forEach((deductible) => {
            // check if there is a base price
            deductibles.add(deductible);
          });

        } else if (isOfferCockpit) {

          insurers.forEach((insurer) => {
            // find deductibles for insurer
             const prices = state.application.prices[insurer.key];

             if (!prices) {
               // console.error('missing prices for: ', insurer.key)
               return
             }

             if (!prices.deductiblesAvailable) {
               // console.error('missing deductiblesAvailable for: ', insurer.key)
               return
             }

             prices.deductiblesAvailable.forEach(deductible => {
               //Sets object automatically ignore duplicates
               // see if there is an offer for the revenue band
               // TODO add some revenue band check.

               deductibles.add(deductible)
             })
           });

        } else {

          insurersByIndustry.forEach((insurer) => {
            // find deductibles for insurer
             const prices = state.application.prices[insurer.key];

             if (!prices) {
               // console.error('missing prices for: ', insurer.key)
               return
             }

             if (!prices.deductiblesAvailable) {
               // console.error('missing deductiblesAvailable for: ', insurer.key)
               return
             }

             prices.deductiblesAvailable.forEach(deductible => {
               //Sets object automatically ignore duplicates
               // see if there is an offer for the revenue band
               // TODO add some revenue band check.

               deductibles.add(deductible)
             })
           });
        }

        return [...deductibles].sort((a, b) => a - b);
      },
      possibleDeductibles2(state, getters) {
        return getters.possibleDeductibles()
      },
      getInsuredSums: (state, getters) => (insurer) => {
        const basePrice = getters.getBasePrice(insurer);
        let indexRevenue = -1;

        if (!basePrice) {
          return [];
        }

        // find revenue band
        basePrice.some((price, index) => {
          if (price[0] >= state.application.inputs.netTurnOver) {
            indexRevenue = index;
            return true;
          }
        });

        return basePrice[0].filter((insuredSum, insuredSumIndex) => insuredSum > 0 &&
            basePrice[indexRevenue] &&
            basePrice[indexRevenue][insuredSumIndex]);
      },
      getDefaultInsuredSums: (state) => {
        const filter = [];
        if (state.application.inputs.netTurnOver <= 750000) {
          filter.push(100000);
          filter.push(250000);
          filter.push(500000);
        } else if (state.application.inputs.netTurnOver > 750000 && state.application.inputs.netTurnOver <= 1000000) {
          filter.push(250000);
          filter.push(500000);
          filter.push(1000000);
        } else if (state.application.inputs.netTurnOver > 1000000 && state.application.inputs.netTurnOver <= 10000000) {
          filter.push(500000);
          filter.push(1000000);
          filter.push(2000000);
        } else if (state.application.inputs.netTurnOver > 10000000 && state.application.inputs.netTurnOver <= 25000000) {
          filter.push(1000000);
          filter.push(2500000);
          filter.push(5000000);
        } else {
          filter.push(1000000);
          filter.push(2500000);
          filter.push(5000000);
        }
        return filter
      },
      possibleInsuredSums: (state, getters) => (insurers = getters.filteredInsurers) => {
        const insuredSums = new Set();
        const filter = getters.getDefaultInsuredSums;
        /* we want to allow all insured sums options for PDF printer (puppeteer) when it comes from the
        affiliate link. Affiliate links always have agency key in inputs. */
        const affiliateAgencyKey = state.application.inputs.key ? state.application.inputs.key : null;

        if (state.application.mode === 'broker' || affiliateAgencyKey) {
          // Broker Portal + Affiliate link
          const insurersByIndustry = getters.insurersByIndustry

          insurersByIndustry.forEach((insurer) => {
            let insurerKey = typeof insurer === 'string' ? insurer : insurer.key;

            getters.getInsuredSums(insurerKey)
              .forEach((insuredSum) => {
                // check if there is a base price
                // different conditions for Victor
                if (insuredSum > 0) {
                  insuredSums.add(insuredSum);
                }
              });
            });
        } else {
          // Website
          insurers.forEach((insurer) => {
            getters.getInsuredSums(insurer.key)
              .forEach((insuredSum) => {
                if (insuredSum > 0 && filter.indexOf(insuredSum) > -1) {
                  insuredSums.add(insuredSum);
                }
              });
          });
        }
        return insurers.length || state.application.mode === 'broker' ? [...insuredSums].sort((a, b) => a - b) : filter;
      },
      // TODO figure out if this is actually being used anywhere
      // I think this is being used only for offer view on customer portal
      possibleInsuredSums2(state, getters) {
        const insurers = getters.filteredInsurersSimple;
        const insuredSums = new Set();
        const filter = [];
        // I do not know if this getter is being used too or it is to be deleted
        // but just to be in the safe side I add the changes here too.
        const affiliateAgencyKey = state.application.inputs.key ? state.application.inputs.key : null;
        const recommendedInsurer = state.application.inputs.insurer
        const isOfferCockpit = state.application.mode === 'broker' && getters.offer && window && window.location.pathname.includes('/offers')

        if (isOfferCockpit && recommendedInsurer && recommendedInsurer !== 'no-recommendation') {
            let insurerKey = typeof recommendedInsurer === 'string' ? recommendedInsurer : recommendedInsurer.key;

            getters.getInsuredSums(insurerKey)
              .forEach((insuredSum) => {
                // check if there is a base price
                if (insuredSum > 0) {
                  insuredSums.add(insuredSum);
                }
              });
        } else if (state.application.mode === 'broker' || affiliateAgencyKey) {
          insurers.forEach((insurer) => {
            let insurerKey = typeof insurer === 'string' ? insurer : insurer.key;

            getters.getInsuredSums(insurerKey)
              .forEach((insuredSum) => {
                // check if there is a base price
                if (insuredSum > 0) {
                  insuredSums.add(insuredSum);
                }
              });
          });
        } else {
          if (state.application.inputs.netTurnOver <= 750000) {
            filter.push(100000);
            filter.push(250000);
            filter.push(500000);
          } else if (state.application.inputs.netTurnOver > 750000 && state.application.inputs.netTurnOver <= 1000000) {
            filter.push(250000);
            filter.push(500000);
            filter.push(1000000);
          } else if (state.application.inputs.netTurnOver > 1000000 && state.application.inputs.netTurnOver <= 10000000) {
            filter.push(500000);
            filter.push(1000000);
            filter.push(2000000);
          } else if (state.application.inputs.netTurnOver > 10000000 && state.application.inputs.netTurnOver <= 25000000) {
            filter.push(1000000);
            filter.push(2500000);
            filter.push(5000000);
          } else {
            filter.push(1000000);
            filter.push(2500000);
            filter.push(5000000);
          }
          insurers.forEach((insurer) => {
            getters.getInsuredSums(insurer.key)
              .forEach((insuredSum) => {
                if (insuredSum > 0 && filter.indexOf(insuredSum) > -1) {
                  insuredSums.add(insuredSum);
                }
              });
          });
        }

        return insurers.length ? [...insuredSums].sort((a, b) => a - b) : filter;
      },
      emptyInsuredSum (state, getters) { // used just to indicate whether there is not selected insured sum
        const insurers = getters.filteredInsurers; // get the current filtered insurers
        const { insuredSum } = state.application.inputs; // get the current insured sum selection
        const insuredSums = getters.possibleInsuredSums(insurers); // possible insured sums require insurers to be provided

        return insuredSums.indexOf(insuredSum) === -1
      },
      filteredQuestions(state) {
        return function (insurer = state.application.inputs.insurer) {
          let insurerKey = insurer;
          if (!insurerKey) {
            console.error('no insurerKey')
          }

          if (!insurerKey || insurerKey === 'no-recommendation') {
            return [];
          }

          let questions = state.application.questions[insurerKey];
          if(!questions) {
            // console.error('missing questions key ', insurerKey)
            return []
          }

          const selectedModules = state.application.inputs.selectedModules[insurer];
          let riskGroup;

          // filter conditions
          questions = questions.filter((question, index) => {
            question.index = (index + 1);
            let conditionsMet = false;

            if (!question.conditions && !question.orConditions) {
              return true;
            }
            const conditions = question.conditions ? 'conditions' : 'orConditions';
            const check = question.conditions ? 'every' : 'some';

            conditionsMet = question[conditions][check]((condition) => {
              if (condition.check === 'indexOf') {
                if (condition.property === 'selectedModules') {
                  return selectedModules && selectedModules.indexOf(condition.value) > -1;
                }
                return get(state.application.inputs, condition.property)
                  .indexOf(condition.value) > -1;
              } else if (condition.check === 'gte') {
                return get(state.application.inputs, condition.property) >= condition.value;
              } else if (condition.check === 'gt') {
                return get(state.application.inputs, condition.property) > condition.value;
              } else if (condition.check === 'lte') {
                return get(state.application.inputs, condition.property) <= condition.value;
              } else if (condition.check === 'lt') {
                return get(state.application.inputs, condition.property) < condition.value;
              } else if (condition.check === 'in') {
                return condition.value.indexOf(get(state.application.inputs, condition.property)) > -1;
              } else if (condition.check === 'neq') {
                return get(state.application.inputs, condition.property) !== condition.value;
              } else if (condition.check === 'eq') {
                if (condition.property === 'riskGroup') {
                  return riskGroup === condition.value;
                }
                return get(state.application.inputs, condition.property) === condition.value;
              }
            });


            return conditionsMet;
          });

          return questions;
        };
      },
      isCheckoutLoadingOverlay(state) {
        return state.application.isCheckoutLoadingOverlay
      },
      revenueRange (state) {
        const inputs = state.application.inputs
        const revenueIndex = Math.max(
          0,
          REVENUE_RANGES.indexOf(inputs.netTurnOver)
        )
        return {
          start: REVENUE_RANGES[revenueIndex - 1],
          end: inputs.netTurnOver
        }
      },
      showContactBanner (state) {
        const step = state.application.activeStep.id
        return step === '1a'
          ? state.application.showContactBanner
          : false
      }
    },
    strict: false
  };

  return store;
}
