<template>
  <Card class="scores-info py-4 mb-3 mb-lg-0">
    <div class="mb-3">
      <b>Rating</b>
    </div>

    <div class="text-sm">
      <p>
        Informationen zu der Methodik unseres Leistungsvergleiches* finden Sie <ButtonComponent
        btnStyle="link"
        behavior="externalLink"
        :link="`${CLIENT_BASE}methodik-vergleich`"
      >
      hier</ButtonComponent>.
        </p>
    </div>
  </Card>
</template>

<script>
import Card from '../../block/Card.vue'
import ButtonComponent from '../../ui/ButtonComponent.vue'

export default {
   components: {
    Card,
    ButtonComponent
  },
  props: ['isOffer', 'isAffiliate'],
  computed: {
    CLIENT_BASE() {
      let CLIENT_BASE = process.env.CLIENT_BASE || process.env.VUE_APP_CLIENT_BASE;
      return CLIENT_BASE.replace(/\/?$/, '/')
    }
  }
}
</script>

<style lang="scss" scoped>

</style>
