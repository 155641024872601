<template>
  <div class="wrap">
    <span>
      {{ truncatedText }}
    </span>
    <ul
      v-if="truncatedList"
      class="list"
      :class="[hasHaghlighting ? 'primary-dots' : '']"
    >
      <li
        v-for="(item, index) in truncatedList"
        :key="index"
        :class="{ 'ml-2': item.text, 'ml-4': item.subText }"
      >
        {{ item.text ? item.text : item.subText ? item.subText : "" }}
      </li>
    </ul>
    <template v-if="!isForceOpen">
      <button
        v-if="questionTitle.length > lenghtLimit || listTruncate.limit > 0"
        id="show-more"
        class="open hide"
        @click.prevent="toggleState"
      >
        {{ state ? "einblenden" : "ausblenden" }}
      </button>
    </template>
  </div>
</template>

<script>
export default {
  name: "truncated-text",
  props: {
    question: {
      type: Object,
      default() {
        return {};
      },
    },
    isForceOpen: {
      type: Boolean,
      default: false,
    },
    hasHaghlighting: {
      type: Boolean,
      default: false,
    },
    categoryKey: {
      type: String,
      default: "",
    },
    externalState: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      lenghtLimit: 300,
      state: true,
    };
  },
  mounted() {
    if (this.isForceOpen) {
      this.state = false;
    }
  },
  computed: {
    questionTitle() {
      return this.question.title || "";
    },
    truncatedText() {
      if (this.questionTitle.length > this.lenghtLimit && this.state) {
        return this.questionTitle.substring(0, this.lenghtLimit) + "...";
      }
      return this.questionTitle;
    },
    isTruncated() {
      return this.questionTitle.length > this.lenghtLimit;
    },
    listTruncate() {
      let length = 0;
      let limit = 0;
      if (this.question.list) {
        this.question.list.forEach((item, index) => {
          length += item.text?.length
            ? item.text.length
            : item.subText?.length
              ? item.subText.length
              : 0;
          if (length > this.lenghtLimit && limit === 0) {
            limit = index;
          }
        });
      }
      return {
        length,
        limit,
      };
    },
    truncatedList() {
      if (this.listTruncate.limit > 0 && this.state) {
        return this.question.list.slice(0, this.listTruncate.limit);
      } else {
        return this.question.list || null;
      }
    },
  },
  methods: {
    toggleState() {
      this.state = !this.state;
      this.$emit("toggle", this.categoryKey);
    },
  },
  watch: {
    externalState(boo) {
      this.state = !boo
    }
  }
};
</script>

<style lang="scss" scoped>
@import "../../../../scss/variables";

.wrap {
  display: flex;
  gap: 0.5rem;
  flex-direction: column;
}

button.open,
button.close {
  font-size: 0.7rem;
  background: none;
  border: none;
  cursor: pointer;
  color: #007bff;
  align-self: flex-end;

  &:hover {
    color: #007bff;
    text-decoration: underline;
  }
}

ul.list {
  padding-left: 1rem;
  text-align: left;
}

ul.list.primary-dots {
  li {
    &::marker {
      color: red; /* Change this to your desired color */
    }
  }
}
</style>
