<template>
    <div class="secondary-page" id="marktstudien_lp">
        <div class="container">

            <div class="row page-title">
                <div class="col-12 col-md-5 ">
                    <h1>Marktstudien</h1>
                </div>

                <div class="col-12 col-md-7">

                    <div class="back-btn" style="text-align:right;margin-bottom:20px;">
                        <router-link class="btn-style" :to="{name: 'Vertriebsmaterialien'}">&larr;Zurück</router-link>
                    </div>

                    <!-- Tabs. Can be restored if needed -->
                    <!-- <div class="tab-controls cyberdirekt">
                        <button
                                v-for="(bucket, index) in content"
                                class="btn-style"
                                @click="visit(bucket)"
                                :class="{primary: getCurrentTab(bucket)}"
                                :key="index">{{bucket.name}}
                        </button>
                    </div> -->

                </div>
            </div>

            <div class="row pdf-std-module"
                 v-for="(pdf, pI) in selectedBucket.files"
                 :key="pI">

                <div class="col-12 col-md-5 std-pdf-preview" @click="$openPDF(getURL(pdf.path))">
                    <div class="l-container">
                        <make-thumb
                                :url="getURL(pdf.path)"
                                :key="pdf.path"></make-thumb>
                        <div class="title-area"><h4></h4></div>
                    </div>
                </div>

                <div class="col-12 col-md-7 markt-content">
                    <div>
                        <h4>{{pdf.label}}</h4>
                        <h6>{{pdf.description}}</h6>
                        <small class="date">{{pdf.date}}</small>
                        <ul>
                            <li v-for="(author, aI) in pdf.authors" :key="aI">{{author}}</li>
                        </ul>

                        <a href="" @click.stop.prevent="$openPDF(getURL(pdf.path))">Download <i
                                class="ion-android-download"></i></a>
                    </div>

                </div>
            </div>

        </div>

    </div>
</template>

<script>
  import MakeThumb from '@/components/shared/MakeThumb'

  export default {
    name: 'Marktstudien',
    metaInfo: {
      title: 'Marktstudien'
    },
    data() {
      return {
        content: [
          {
            name: 'Cyber-Sicherheit',
            slug: 'cyber-sicherheit',
            files: [
              {
                label: 'Faktenblatt Cyberkriminalität',
                description: 'So steht es um die IT-Sicherheit im deutschen Mittelstand',
                path: 'cyber-sicherheit/factsheet-it-sicherheit-im-mittelstand-2023-data.pdf',
                date: '2023',
                authors: [
                  'Gesamtverband der Versicherer'
                ]
              },
              {
                label: 'IT-Sicherheits-Report',
                description: 'Die Lage der IT-Sicherheit in Deutschland',
                path: 'cyber-sicherheit/Lagebericht2023.pdf',
                date: '2023',
                authors: [
                  'Bundesamt für Sicherheit in der Informationstechnik'
                ]
              },
              {
                label: 'CyberDirekt Risikolage',
                description: 'So gut ist der deutsche Mittelstand auf Hacking-Angriffe vorbereitet',
                path: 'cyber-sicherheit/CyberDirekt_Risikolage_2022.pdf',
                date: '2022',
                authors: [
                  'CyberDirekt GmbH'
                ]
              }
            ]
          }
        ]
      }
    },
    beforeRouteEnter(to, from,next) {
    const allowedRoutes = ['cyber-sicherheit', 'cyber-bedrohungslage', 'folgen-eines-hackerangriffs']
    const isAllowed = allowedRoutes.findIndex(ele =>  ele === to.params.slug)
     if(isAllowed === -1) {
       console.error('missing slug')
        next('marktstudien/cyber-sicherheit')
      } else {
        next()
      }
    },
    methods: {
      visit(bucket) {
        this.$router.push({
          name: 'MarktstudienLP',
          params: {
            slug: bucket.slug
          }
        })
      },
      getURL(path) {
        return 'https://s3.eu-central-1.amazonaws.com/de-cyberdirekt-uploads-public/marktstudien/' + path
      },
      getCurrentTab(bucket) {
        return bucket.slug === this.$route.params.slug
      }
    },
    computed: {
      selectedBucket() {
        return this.content.find(bucket => this.getCurrentTab(bucket))
      }
    },
    components: {
      MakeThumb
    }
  }
</script>

<style lang="scss" scoped>
    @import '../scss/variables';
    @import '../scss/mixins';
    @import '~bootstrap/scss/functions';
    @import '~bootstrap/scss/variables';
    @import '~bootstrap/scss/mixins';

    .page-title {
        margin-bottom: calc($standardGutter * 2);

        .back-btn {
            text-align: left;
        }
    }

    @include media-breakpoint-down(md) {
        .tab-controls {
            margin-left: 0;
            text-align: left;
            margin-bottom: $standardGutter;
            display: block;

            button {
                width: 100%;
                margin-bottom: 11px;
                height: auto;
            }
        }

        .page-title {
            margin-bottom: $standardGutter;
        }
    }

</style>
