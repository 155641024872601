<template>
  <div class="accordion-list" :class="{'accordion-list--offer': mode === 'offer', 'score-visible': isScoreVisible}">
    <AccordionTable
      v-for="(group, groupIndex) in insuranceProperties.groups"
      :key="`acc-group-${groupIndex}`"
      :id="`acc-group-${groupIndex}`"
      :info="group.info"
      :mode="mode"
      :isOpenOnInit="true"
      :isOpenOnInitForce="true"
      class="page-break"
    >
      <template #header>{{ group.label }}</template>
      <template #body>
        <ChildAccordion
          v-for="(subgroup, subgroupIndex) in group.subgroups"
          :key="`acc-group-${groupIndex}__acc-subgroup-${subgroupIndex}`"
          :id="`acc-group-${groupIndex}__acc-subgroup-${subgroupIndex}`"
          :isOpenOnInit="false"
          :isOpenOnInitForce="false"
          :mode="mode"
        >
          <template #header>
            <div class="trigger-wrap sticky-col" :class="{'trigger-wrap--no-score': !isScoreVisible}">
              <b-button
                block
                v-b-toggle="`acc-group-${groupIndex}__acc-subgroup-${subgroupIndex}`"
                variant="info"
                class="text-left d-flex align-items-center btn-prime btn-prime-light"
              >
                <div class="arrow d-print-none">
                  <Icon name="arrow-short-down" :width="12" :height="8" class="d-print-none"></Icon>
                </div>
                <span>{{ subgroup.label }}</span>
                <Icon
                  v-if="subgroup.info"
                  v-tooltip.top-start="subgroup.info"
                  @click.native.stop
                  class="info-icon d-print-none"
                  name="info-2"
                />
              </b-button>
            </div>
            <transition name="scores">
              <div v-if="isScoreVisible" class="precentage-row gap-sm syncscroll" name="trTable">
                <div
                  class="precentage-col"
                  :class="[
                  `precentage-col--${insurers.length}`,
                   {'precentage-col--disabled': insurer.disabled},
                   insurersWithScores[insurerIndex] && insurersWithScores[insurerIndex].groups[groupIndex] && insurersWithScores[insurerIndex].groups[groupIndex].subgroups[subgroupIndex] ? `precentage-score-${insurersWithScores[insurerIndex].groups[groupIndex].subgroups[subgroupIndex].precentageScore}` : '',
                   isRecommendationDeclinedMixin(insurer.key) && isOffer ? 'insurer--recommendation-declined' : ''
                  ]"
                  v-for="(insurer, insurerIndex) in innerInsurers"
                  :key="insurer.key"
                >
                  <Precentage
                    :isHidden="scoringHidden(subgroup.key)"
                    :progress="insurersWithScores?.length && insurersWithScores[insurerIndex] ? insurersWithScores[insurerIndex].groups[groupIndex].subgroups[subgroupIndex].precentageScore : 0"
                    :insurer="insurer"
                  ></Precentage>
                </div>
              </div>
            </transition>
          </template>
          <template #body>
            <template v-for="(module, modIndex) in subgroup.modules">
              <Row
                v-if="!module.submodules"
                :key="`acc-module-${modIndex}`"
                class="acc-child__row"
              >
                <template #label>
                  <div>
                    <span>{{ module.label }}</span>
                    <Icon
                      v-if="module.info"
                      v-tooltip.top-start="module.info"
                      class="info-icon d-print-none"
                      name="info-2"
                    />
                  </div>
                </template>
                <template #properties>
                  <div
                    class="tb-cell"
                    :class="[
                      `tb-cell--${insurers.length}`,
                      {'tb-cell--disabled': insurer.disabled,
                      'insurer-recommended': insurer.key === recommendedInsurer},
                      isRecommendationDeclinedMixin(insurer.key) && isOffer ? 'insurer--recommendation-declined' : ''
                    ]"
                    v-for="insurer in innerInsurers"
                    :key="insurer.key"
                  >
                    <div class="recommendation-frame"></div>
                    <div v-if="mode === 'offer'" class="tb-cell__title">
                      <span>{{ module.label }}</span>
                      <Icon
                        v-if="module.info"
                        v-tooltip.top-start="module.info"
                        class="info-icon d-print-none"
                        name="info-2"
                      />
                    </div>
                    <svg
                      v-if="isAvailable(insurer, module.key)"
                      width="20"
                      height="17"
                      viewBox="0 0 20 17"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M7 16.42.79 10.21l2.83-2.83L7 10.77 16.88.88l2.83 2.83L7 16.42Z"
                        fill="#51BC7E"
                      />
                    </svg>
                    <div
                      v-else-if="isOptional(insurer, module.key)"
                      class="optional-radio"
                    >
                      <input
                        class="optional"
                        type="checkbox"
                        :value="module.key"
                        v-model="inputs.selectedModules[insurer.key]"
                        v-on:change="optionalChanged(insurer.key, module.key)"
                        :id="insurer.key + '-' + module.key"
                        :disabled="mode === 'offer'"
                      />
                      <label :for="insurer.key + '-' + module.key"></label>
                      <div class="selected-module-title text-sm">
                        <template
                          v-if="
                            inputs.selectedModules[insurer.key] && inputs.selectedModules[insurer.key].includes(
                              module.key
                            )
                          "
                          >abwählbar</template
                        >
                        <template v-else>anwählbar</template>
                      </div>
                    </div>
                    <template v-else-if="insurer.properties[module.key] ? insurer.properties[module.key].state === 'info' : ''"></template>
                    <!-- function below doesn't work as expected -->
                    <!-- <template v-else-if="isPropertyInfo(insurer.properties, module.key, 'info')"></template> -->
                    <svg
                      v-else
                      width="16"
                      height="4"
                      viewBox="0 0 16 4"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path d="M16 4H0V0h16" fill="#31A9E0" />
                    </svg>
                    <div
                      class="note"
                      v-if="
                        insurer.properties[module.key] && insurer.properties[module.key].note && !compressedMode
                      "
                      v-html="getNote(insurer.properties[module.key])"
                    ></div>
                  </div>
                </template>
              </Row>
              <ChildAccordion
                v-else
                :id="`acc-module-${modIndex}`"
                :key="`acc-module-${modIndex}`"
                :nestedLvl="3"
                class="acc-child__row"
                :mode="mode"
              >
                <template #header>
                  <!-- {{module.key}} - {{module.submodules.length}} -->
                  <div class="tb-cell-title-wrap w-full nested-lvl-3">
                    <div class="trigger-wrap col w-full">
                      <b-button
                        block
                        v-b-toggle="`acc-module-${modIndex}`"
                        variant="info"
                        class="acc-btn text-left btn-prime tb-cell"
                      >
                        <div class="acc-child__header-label">
                          <div class="arrow d-print-none">
                            <Icon
                              name="arrow-short-down"
                              :width="12"
                              :height="8"
                              class="d-print-none"
                            ></Icon>
                          </div>
                          <span>{{ module.label }}</span>
                          <Icon
                            v-if="module.info"
                            v-tooltip.top-start="module.info"
                            @click.native.stop
                            class="info-icon d-print-none"
                            name="info-2"
                          />
                        </div>
                      </b-button>
                    </div>
                  </div>
                </template>
                <template #body>
                  <Row
                    v-for="(submodule, submodIndex) in module.submodules"
                    :key="`acc-submod-${submodIndex}`"
                    class="acc-child__row"
                  >
                    <template #label>
                      <div>
                        <span>{{ submodule.label }}</span>
                        <Icon
                          v-if="submodule.info"
                          v-tooltip.top-start="submodule.info"
                          class="info-icon d-print-none"
                          name="info-2"
                        />
                      </div>
                    </template>
                    <template #properties>
                      <div
                        class="tb-cell"
                        :class="[
                          `tb-cell--${insurers.length}`,
                          {'tb-cell--disabled': insurer.disabled, 'insurer-recommended': insurer.key === recommendedInsurer},
                          isRecommendationDeclinedMixin(insurer.key) && isOffer ? 'insurer--recommendation-declined' : ''
                        ]"
                        v-for="insurer in innerInsurers"
                        :key="insurer.key"
                      >
                        <div class="recommendation-frame"></div>
                        <div v-if="mode === 'offer'" class="tb-cell__title">
                          <span>{{ submodule.label }}</span>
                          <Icon
                            v-if="submodule.info"
                            v-tooltip.top-start="submodule.info"
                            class="info-icon d-print-none"
                            name="info-2"
                          />
                        </div>
                        <svg
                          v-if="isAvailable(insurer, submodule.key)"
                          width="20"
                          height="17"
                          viewBox="0 0 20 17"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path
                            d="M7 16.42.79 10.21l2.83-2.83L7 10.77 16.88.88l2.83 2.83L7 16.42Z"
                            fill="#51BC7E"
                          />
                        </svg>
                        <div
                          v-else-if="isOptional(insurer, submodule.key)"
                          class="optional-radio"
                        >
                          <input
                            class="optional"
                            type="checkbox"
                            :value="submodule.key"
                            v-model="inputs.selectedModules[insurer.key]"
                            v-on:change="
                              optionalChanged(insurer.key, submodule.key)
                            "
                            :id="insurer.key + '-' + submodule.key"
                            :disabled="mode === 'offer'"
                          />
                          <label
                            :for="insurer.key + '-' + submodule.key"
                          ></label>
                          <div class="selected-module-title text-sm">
                            <template
                              v-if="
                                inputs.selectedModules[insurer.key] && inputs.selectedModules[insurer.key].includes(
                                  submodule.key
                                )
                              "
                              >abwählbar</template
                            >
                            <template v-else>anwählbar</template>
                          </div>
                        </div>
                        <svg
                          v-else
                          width="16"
                          height="4"
                          viewBox="0 0 16 4"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path d="M16 4H0V0h16" fill="#31A9E0" />
                        </svg>
                        <div
                          class="note"
                          v-if="
                            insurer.properties[submodule.key] &&
                            insurer.properties[submodule.key].note &&
                            !compressedMode
                          "
                          v-html="getNote(insurer.properties[submodule.key])"
                        ></div>
                      </div>
                    </template>
                  </Row>
                </template>
              </ChildAccordion>
            </template>
          </template>
        </ChildAccordion>
      </template>
    </AccordionTable>
  </div>
</template>
<script>
import AccordionTable from '../block/AccordionTable/AccordionTable.vue'
import ChildAccordion from '../block/AccordionTable/ChildAccordion'
import Row from '../block/AccordionTable/Row'
import Precentage from '../block/Precentage'
import Icon from '../ui/Icon'
import tariffsWithScanMixin from '../../../mixins/tariffsWithScanMixin.js'
import { mapGetters } from 'vuex'

export default {
  components: {
    AccordionTable,
    ChildAccordion,
    Row,
    Precentage,
    Icon
  },
  mixins: [tariffsWithScanMixin],

  props: {
    insurers: Array,
    inputs: Object,
    isAvailable: Function,
    isOptional: Function,
    isSelected: Function,
    getNote: Function,
    optionalChanged: Function,
    compressedMode: Boolean,
    insuranceProperties: Object,
    mode: String,
    sortBy: String,
    isOffer: {
      type: Boolean,
      default: false,
      required: false
    }
  },

  computed: {
    ...mapGetters([
      'isInitialLoadSortingRefresh']
    ),
    isScoreVisible: {
      get() {
        return this.$store.state.checkout.application.isScoreVisible
      },
      set(newValue) {
        this.$store.commit('SET_SCORE_VISIBILITY', newValue)
      }
    },
    insurersWithScores () {
      return this.$store.getters.getInsurersWithScoresSorted
    }
  },

  data() {
    return {
      innerInsurers: this.insurers,
      recommendedInsurer: this.$store.state.offer?.offer?.insurer
    }
  },

  watch: {
    sortBy() {
      // update when sorting changes
      this.innerInsurers = this.insurers
    },
    insurers: {
      handler (newvalue) {
        this.innerInsurers = newvalue
      },
      deep: true
    },
  },


  methods: {
    scoringHidden(key) {
      return key === 'zusatzinformation' || key === 'addon'
    },
    generateRandomScore(min, max) {
      return Math.floor(Math.random() * (max - min + 1) + min)
    },
    isPropertyInfo(insurerProperties, key, property) {
      if(insurerProperties[key]) {
        return insurerProperties[key][property] === 'info'
      }
      return false
    },
    getAverage(numArr) {
      let sum = 0
      numArr.forEach((num) => {
        sum += num
      })
      const average = sum && numArr.length ? sum / numArr.length : 0
      return average
    },

    getSubmoduleScore (insurer, group, subgroup, module, submodule) {
      const currentGroup = this.insuranceProperties.groups.find(oneGroup => oneGroup.key === group.key)
      const currentSubgroup = currentGroup.subgroups.find(oneSubgroup => oneSubgroup.key === subgroup.key)
      const currentModule = currentSubgroup.modules.find(oneModule => oneModule.key === module.key)
      const currentSubmodule = currentModule.submodules.find(oneSubmodule => oneSubmodule.key === submodule.key)
      return currentSubmodule.score
    },

    forceUpdateInsurers () {
      this.innerInsurers = this.insurers
    }
  },

}
</script>

<style lang="scss" scoped>
@import '../../../scss/variables';
@import '~bootstrap/scss/functions';
@import '~bootstrap/scss/variables';
@import '~bootstrap/scss/mixins';
@import '../../../scss/print/insurersPrint.scss';
@import './sharedAccordionStyles.scss';


// .accordion-list {
//   display: flex;
//   flex-direction: column;
//   gap: 0.5rem;

//   @include media-breakpoint-down(sm) {
//     min-width: fit-content;
//   }
// }

.acc-child {
  ::v-deep &__header {
    @include media-breakpoint-down(sm) {
      gap: 0.5rem;
    }
  }

  ::v-deep &__row {
    position: relative;
  }

  &__header-label {
    display: flex;
    align-items: center;
    // padding-left: 1.5rem;

    span {
      white-space: break-spaces;
    }

    @include media-breakpoint-down(sm) {
      .arrow .icon {
        color: $primary;
      }
    }
  }

  &__body {
    display: flex;
    flex-direction: column;
    gap: 0.5rem;
    padding-top: 0.5rem;
  }

  // .trigger-wrap {
  //   width: 402px;
  //   padding-left: 2rem;
  //   background: #fff;

  //   @include media-breakpoint-down(sm) {
  //     min-width: 290px;
  //     width: 290px;
  //     padding-left: 0;
  //   }

  //   &--no-score {
  //     position: sticky;
  //     left: 0.5rem;
  //     width: 100%;
  //     max-width: calc(100vw - 1.25rem);

  //     @include media-breakpoint-down(xs) {
  //       max-width: calc(100vw - 2.85rem);
  //     }
  //   }

  //   .btn {
  //     padding-left: 1.5rem;
  //   }
  // }

  ::v-deep .tb-cell-title-wrap {
    background: #fff;
    padding-left: 2rem;

    @include media-breakpoint-down(sm) {
      min-width: 290px;
      width: 290px;
      word-break: break-word;
      padding-left: 0;
    }

    &.nested-lvl-3 {
      // padding-left: 4rem;

      @include media-breakpoint-down(sm) {
        padding-left: 0;
      }

      .trigger-wrap {
        padding-left: 0;
        padding-right: 0;
      }
    }

    .tb-cell--title {
      padding: 0.5rem 0.5rem 0.5rem 4rem;

      @include media-breakpoint-down(sm) {
        font-size: 0.875rem;
        padding: 0.5rem 0.5rem 0.5rem 3.5rem;
      }
    }
  }

  &__row:nth-child(odd) {
    ::v-deep .tb-cell {
      background: #e0f2fa;
    }
  }

  &__row:nth-child(even) {
    ::v-deep .tb-cell {
      background: #f5fbfd;
    }
  }

  ::v-deep .acc-child .acc-child__body {
    .tb-cell-title-wrap {
      padding-left: 4.75rem;

      @include media-breakpoint-down(sm) {
        padding-left: 0;
      }

      .tb-cell--title {
        padding: 0.5rem 0.5rem 0.5rem 2rem;
      }
    }
  }

  .acc-child .acc-child {
    &__row:nth-child(odd) {
      ::v-deep .tb-cell {
        background: #ececec;
      }
    }

    &__row:nth-child(even) {
      ::v-deep .tb-cell {
        background: #f6f6f6;
      }
    }
  }
}

.precentage-row {
  display: flex;
  gap: 0.5rem;
  height: inherit;

  @include media-breakpoint-down(sm) {
    gap: 0.5rem;
  }
}

.precentage-col {
  width: 290px;

  &--disabled {
    filter: grayscale(100%);
    opacity: 0.5;
  }
}

.selected-module-title {
  display: none;

  @include media-breakpoint-up(md) {
    display: block;
  }

  @media print {
    display: block;
  }
}

.scores-leave,
.scores-enter-to {
  opacity: 1;
}
.scores-enter-active,
.scores-leave-active {
  transition: opacity 0.4s;
}
.scores-enter,
.scores-leave-to {
  opacity: 0;
}


.tb-cell {
  &--disabled {
    filter: grayscale(100%);
    opacity: 0.5;
  }
}

// .accordion-list--offer {
//   .tb-cell {
//     &__title {
//       display: none;

//       @include media-breakpoint-down(xs) {
//         display: block;
//         font-size: 0.85rem;
//         font-weight: bold;
//         word-break: break-word;
//         hyphens: auto;
//       }
//     }
//   }
// }
</style>
